/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.4/dist/jquery.min.js
 * - /npm/popper.js@1.16.1/dist/umd/popper.min.js
 * - /gh/twbs/bootstrap@4.3.1/dist/js/bootstrap.min.js
 * - /gh/twbs/bootstrap@4.3.1/dist/js/bootstrap.min.js
 * - /npm/moment@2.24.0/moment.min.js
 * - /npm/moment@2.29.1/locale/fr-ca.js
 * - /npm/nouislider@8.5.1/distribute/nouislider.min.js
 * - /npm/vue@2.7.16/dist/vue.min.js
 * - /gh/jpillora/notifyjs@0.4.2/dist/notify.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
